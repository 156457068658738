@import '/src/service/styles/colors.scss'; 

@mixin general-text{
    margin: 0;
    padding: 10px;
    box-sizing: border-box;
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;
    // font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif ;
    font-family: Roboto, Tahoma, Geneva, Verdana, sans-serif;
}

@mixin small-text{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-size: 13px;
    line-height: 15px;
    font-weight: 500;
    // font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-family: Roboto, Tahoma, Geneva, Verdana, sans-serif;
}

@mixin menu-link{
    @include general-text;
    text-decoration: none;
    color: $dark-link-color;
}

@mixin flex{
    width: 100%;
    display: flex;
    box-sizing: border-box;
}

@mixin navbutton-bordered{    
    @include small-text;
    /*
    padding: 17px;
    border: 1px solid $accent-second;
    color: $accent-second;
    border-radius: 20px;
    text-decoration: none;
    display: flex;
    align-items: center;
    height: 45px;
    */
    border-radius: 5px;
    background-color: $btn-color;
    color: white;
    text-transform: capitalize;
    padding: 10px 15px;    
    text-decoration: none;
    cursor: pointer;
}


@mixin button-rounded{    
    @include small-text;
    border-radius: 20px;
    background-color: $btn-color;
    color: white;
    text-transform: capitalize;
    padding: 12px 18px;    
    text-decoration: none;   
  border: 0;
  max-width: 170px;
  cursor: pointer;
}