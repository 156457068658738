@import '/src/service/styles/colors.scss'; 
@import '/src/service/styles/styles.scss';

.header {
  @include flex;
  justify-content: space-between;
  margin: 0;
  padding: 15px 25px;
  align-items: center;
  background-color: $soft-bg;
}

.logoAndMenu {
  @include flex;  
  align-items: center;
  margin: 0;
  padding: 0;
}

.menu {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  align-items: center;
}

.menu__item {
  padding: 0;
  margin: 0 40px 0 0;
  &:last-of-type {
    margin: 0;
  }
}

.menu__link {
  @include menu-link;
}

.logo {
  width: 175px;
  height: 60px;
  object-fit: cover;
  margin: 0 80px 0 0;
  padding: 0;
}

.header__button {
  @include navbutton-bordered;
}
