@import '/src/service/styles/colors.scss';
@import '/src/service/styles/styles.scss'; 

.page {
  @include flex;
  background-color: white;
  justify-content: center;
  min-height: 100vh;
  background-image: linear-gradient($soft-bg, white);
  overflow: hidden;
}

.container {
  @include flex;
  max-width: 1140px;
  // background-color: white;
  flex-direction: column;
}

.par {
  @include general-text;
}
