
/* main site colors */
$accentmain: rgb(175, 87, 164);
$textcolor: rgb(0, 20, 2);
$soft: rgb(7, 129, 64);
$accent: rgb(7, 129, 64);
$accent-second: rgb(175, 87, 164);
$btn-color: blueviolet;
$dark-link-color: #272D4E;
$light-link-color:rgb(113, 125, 191);
/* as in fig layout: */
$soft-bg: #e8f1ff;

/* catalog showing colors: */ 

$themes: (
  'main': (
    $accentmain: rgb(175, 87, 164),
    $textcolor: rgb(0, 20, 2),
    $soft: rgb(7, 129, 64),
  ),
  'violet': (
    $accentmain: rgb(98, 56, 171),
    $soft: #bebbff,
    $textcolor: rgb(27, 17, 81),
  ),
  'green': (
    $accentmain: #00885a,
    $soft: #1b8f8f,
    $textcolor: rgb(107, 50, 6),
  ),
);

@function color-get($group-name, $color-name) {
  @if (map-has-key($themes, $group-name)) {
    $group: map-get($themes, $group-name);
    @if (map-has-key($group, $color-name)) {
      @return map-get($group, $color-name);
    }
  }
  @return false;
}
