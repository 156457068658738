@import '/src/service/styles/styles.scss';
@import '/src/service/styles/colors.scss';
@import '/src/service/styles/changeTheme.scss';

.themes {
  @include themeChange($accentmain, $textcolor, $soft);
}

.themeBox {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  justify-content: center;
  align-items: center;
}

/* banner */

.banner {
  /* background-image: url(https://images.unsplash.com/photo-1517677208171-0bc6725a3e60?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80);
  */
  border: 1px solid yellow;
  width: 100%;
  min-height: 45vh;
  background-position: 70% 30%;
  background-size: cover;
  background-color: transparent;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  position: relative;
  z-index: 2;
}

.floatingImg {
  position: absolute;
  top: -200px;
  right: 0;
  width: 700px;
  height: 700px;
  object-fit: cover;
  object-position: 67% 20%;
  opacity: 0.6;
  z-index: 1;

}

.overlay {
  padding: 30px;
  margin: 0;
  width: 100%;
  min-height: 45vh;
  display: grid;
  grid-template-columns: 48% 48%;
  // background-color: rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
}

.textpart {
  width: 100%;
  min-height: 45vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-color: transparent;
  // background-color: rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
}

.banner__title {
  font-size: 50px;
  line-height: 70px;
  margin: 0 0 30px 0;
  padding: 0;
  font-family: Roboto, Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 600;
  color: $dark-link-color;
  max-width: 500px;
}

.banner__text {
  font-size: 16px;
  line-height: 20px;
  margin: 0 0 30px 0;
  padding: 0;
  font-family: Roboto, Tahoma, Geneva, Verdana, sans-serif;
}

.banner__button {
  @include button-rounded;
}

.section {
  width: 100%;
  margin: 50px 0 0 0;
  padding: 0;
}

.subtitle {
  font-family: Roboto, Tahoma, Geneva, Verdana, sans-serif;
  font-size: 32px;
  line-height: 46px;
  font-weight: 600;
  text-align: center;
  color: $dark-link-color;
}

.benefitsList {
  display: grid;
  grid-template-columns: 33% 33% 33%;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.benefit {
  border: 1px solid blue;
  min-height: 200px;
}

.secondBen {
  margin: 30px 0 0 0;
}

.thirdBen {
  margin: 60px 0 0 0;
}

.imagespart {
  position: relative;
  width: 100%;
  max-width: 450px;
  min-height: 45vh;
  z-index: 3;
}

.previewImg {
  object-fit: cover;  

  border-radius: 10px;
}
.previewImgFirst {
  width: 150px;
  height: 100px;  
}
.previewImgSec {
  width: 300px;
  height: 250px;  
}
.previewImgThird {
  width: 200px;
  height: 160px;  
}

.banner_productimg {
  position: absolute;
}

.floatfirst {
  top: 30px;
  left: 20px;
}

.floatsecond {
  top: 60px;
  left: 80px;
}

.floatthird {
  top: 190px;
  left: 280px;
}